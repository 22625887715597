import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <footer className="footer container">
        <div>© 2020 Alex Xiong</div>
        <div>
          <a href="https://github.com/alxiong" title="GitHub" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-github fa-2x" />
          </a>
          <a
            href="https://twitter.com/alex_xiong_"
            title="Twitter"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-twitter fa-2x" />
          </a>
          <a href="https://gitlab.com/alxiong" title="GitLab" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-gitlab fa-2x" />
          </a>
        </div>
      </footer>
    );
  }
}
