const config = {
  siteTitle: "Alex Luoyuan Xiong",
  siteTitleShort: "Alex Xiong",
  siteTitleAlt: "Alex Xiong",
  siteLogo: "/logos/logo.svg",
  siteUrl: "https://alexxiong.com",
  pathPrefix: "",
  dateFromFormat: "YYYY-MM-DD",
  dateFormat: "MMMM Do, YYYY",
  siteDescription: "Alex Xiong is a cryptographic engineer",
  postDefaultCategoryID: "Tech",
  userName: "Alex",
  userEmail: "hi@alexxiong.com",
  userTwitter: "alex_xiong_",
  menuLinks: [
    {
      name: "About",
      link: "/about/"
    },
    {
      name: "Blog",
      link: "/blog/"
    }
  ],
  themeColor: "#ff6654", // Used for setting manifest and progress theme colors.
  backgroundColor: "#f7f8fa"
};

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  config.pathPrefix = "";
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
  config.siteUrl = config.siteUrl.slice(0, -1);

module.exports = config;
