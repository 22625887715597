import React, { Component } from "react";
import Helmet from "react-helmet";
import ThemeContext from "../context/ThemeContext";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import config from "../../data/SiteConfig";
import favicon from "../images/favicon.png";
import "../styles/main.scss";

export default class MainLayout extends Component {
  static contextType = ThemeContext;

  render() {
    const { dark } = this.context;
    const { children } = this.props;
    let themeClass = "";

    if (dark) {
      themeClass = "dark";
    } else {
      themeClass = "";
    }

    return (
      <>
        <Helmet
          bodyAttributes={{
            class: `theme ${themeClass}`
          }}
          htmlAttributes={{ lang : "en" }}
        >
          <meta name="description" content={config.siteDescription} />
          <link rel="shortcut icon" type="image/png" href={favicon} />
          <script
            src="https://kit.fontawesome.com/1b24ed8d34.js"
            crossOrigin="anonymous"
          />
          {/* FIXME: this is still quite hardcoded */}
          {dark? (
            <style type="text/css">{`
                html {
                  background-color: #202020; 
                } 
              `}
            </style>
          ) : (
            <style type="text/css">{`
                html {
                  background-color: #fcfaf6; 
                } 
              `}
            </style>
          )}
        </Helmet>
        <Navigation menuLinks={config.menuLinks} />
        {dark ? (
          <main id="main-content" className="main-content-dark">
            {children}
          </main>
        ) : (
          <main id="main-content">{children}</main>
        )}
        <Footer />
      </>
    );
  }
}
